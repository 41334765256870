/* Haunted Mansion Project*/

#haunted-mansion-header {
  display: flex;
  justify-content: space-around;
}

.haunted-mansion-project-link {
  background: lightblue;
}

#haunted-mansion-layout-image {
  width: 100%;
  margin: 0.5em auto 0.5em;
}

#haunted-mansion-mermaid-chart {
  width: 100%;
  margin: 0.5em auto 0.5em;
}

#haunted-mansion-video {
  width: 100%;
  min-height: 10em;
  margin: 1em 0 1em 0;
}
