:root {
  /* Main elements*/
  --main-background-color: #feffe8;
  --main-font-color: #332600;

  --card-header-font-color: #fffcfc;
  --card-container-border-color: rgb(0, 0, 0);
  --card-background-color: #bfb9f9;
  --card-text-background-color: rgb(216, 216, 250);
  --card-box-border: 1px solid rgb(0, 0, 0);
  --card-box-shadow: 0.5rem 0.5rem 0.1rem var(--card-box-shadow-color);
  --card-box-shadow-color: #d5d7b9;
  --card-box-border-radius: 5%;
  --card-margin: 1em 0 1em 0;
  --card-header-background: #7d72d0;
  --card-header-box-shadow: 0.5rem 0.5rem 0.1rem var(--card-box-shadow-color);

  /* Links*/
  --link-visited: #fefbfb;
  --link-hover-focus: #a0dff4;
  --link-active: #ff0000;
  --link: #fefbfb;

  /* Navbar */
  --header-visited: #fefbfb;
  --header-visited-text-shadow: 0.04em 0.04em rgb(68, 3, 3);
  --header-hover-focus: #a0dff4;
  --header-hover-focus-text-shadow: 0.04em 0.04em rgb(253, 217, 217);
  --header-active: #ff0000;
  --header-active-text-shadow: 0.04em 0.04em #46cefb;

  --nav-header-background-color: #7d72d0;
  --nav-border-color: rgb(0, 0, 0);

  /* Homepage */
  --click-to-enter-color: #01305e;
  --click-to-enter-visited: #01305e;
  --click-to-enter-hover-focus: #045fba;
  --click-to-enter-active: var(--header-active);

  /* Tab Selectors */
  --tab-selectors: var(--card-background-color);
  --tab-selector-hover: #f6f1ab;
  --tab-selector-active: #ffc296;

  --project-ul-font-size: 1.3rem;
  --project-ul-font-size-mobile: 1rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Verdana;
  letter-spacing: 0.1em;
  user-select: none;
}

.image-border {
  border: 3px solid var(--card-container-border-color);
}

#routes {
  margin-top: 4em;
}

.no-wrap {
  white-space: nowrap;
}

body {
  background-color: var(--main-background-color);
  color: var(--main-font-color);
  height: 100%;
  background-size: 40px 40px;
  background-image: linear-gradient(
      to right,
      rgb(145, 145, 145) 3px,
      transparent 3px
    ),
    linear-gradient(to bottom, rgb(145, 145, 145) 3px, transparent 1px);
}

.card-container {
  background: var(--card-background-color);
  border: 3px solid var(--card-container-border-color);
}

.card-container {
  padding: 1em;
  margin-bottom: 1em;
}

.card-container:last-child() {
  padding: none;
}

.card-header {
  background: rgb(115, 115, 173);
  text-align: center;
  color: var(--card-header-font-color);
  font-size: 1.1em;
  border-left: 3px solid var(--card-container-border-color);
  border-top: 3px solid var(--card-container-border-color);
  border-right: 3px solid var(--card-container-border-color);
}

.card-description {
  padding: 1rem;
  border: var(--card-box-border);
  background-color: var(--card-text-background-color);
  border-radius: var(--card-box-border-radius);
  margin: var(--card-margin);
}

.project-ul {
  padding-top: 1em;
}
.project-ul > * {
  font-size: var(--project-ul-font-size);
  font-style: italic;
  padding-left: 0.5em;
}

@media (min-width: 800px) {
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    font-family: Verdana;
    letter-spacing: 0.1em;
    user-select: none;
  }

  html,
  body {
    height: 100%;
  }

  #app {
    height: 100%;
  }

  #app-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  #routes {
    margin-top: 3em;
    padding-left: 2em;
    padding-right: 2em;
    max-width: 27em;
  }

  .card-container {
    background: var(--card-background-color);
    border: 3px solid var(--card-container-border-color);
    padding: 1em;
    box-shadow: var(--card-box-shadow);
  }

  .card-description {
    padding: 1rem;
    border: var(--card-box-border);
    background-color: var(--card-text-background-color);
    border-radius: var(--card-box-border-radius);
    margin: var(--card-margin);
  }

  .card-header {
    background: var(--card-header-background);
    box-shadow: var(--card-header-box-shadow);
    text-align: center;
    color: var(--card-header-font-color);
    font-size: 1.1em;
    border-left: 3px solid var(--card-container-border-color);
    border-top: 3px solid var(--card-container-border-color);
    border-right: 3px solid var(--card-container-border-color);
  }
}
