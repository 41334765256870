@import '../App/App.css';

#footer {
    display:flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: .5em auto .5em;
    width: 100%;
    background: var(--nav-header-background-color);
    border-top: 3px solid var(--nav-border-color);
}

#footer > a {
    color: var(--link);
    text-decoration: none;
}

#footer > a:active {
    color: var(--link-active);
}

#footer > a:focus {
    color: var(--link-focus);
}

#footer > a:visited {
    color: var(--link-visited);
}