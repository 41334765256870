@import "../App/App.css";

#homepage-header {
  color: var(--main-font-color);
}

#homepage-header-circle{
  width: 15em;
  height: 15em;
  border-radius: 50%;
  background: #E48EEE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--card-box-shadow)
}

#homepage-header > * {
  font-size: 35px;
}

.circle {
  z-index: -1;
  position: absolute;
  display: inline-block;
  background: purple;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

#homepage-background {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: purple;
  background-image: url(/src/components/Homepage/Test-Homepage-Background-Web-Dev-Resume.png);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

#click-to-enter {
  color: var(--click-to-enter-color);
}
#click-to-enter:visited {
  color: var(--click-to-enter-visited);
}
#click-to-enter:hover,
#click-to-enter:focus {
  color: var(--click-to-enter-hover-focus);
}
#click-to-enter:active {
  color: var(--click-to-enter-active);
}



/* Media Queries */

@media (max-width: 700px) {
  #homepage-header > * {
    font-size: 0.9em;
  }

  #homepage-header-circle > h2 {
    width: 80%;
    text-align: center;
  }
}

@media (max-height: 500px) {
  #homepage-header > * {
    font-size: 0.9em;
  }

  #homepage-header-circle > h2 {
    width: 80%;
    text-align: center;
  }

  #homepage-header-circle {
    position: relative;
    top: 0;
  }
}