@import "../../App/App.css";

.tech-stack-logo-container {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: radial-gradient(
    var(--card-header-background),
    var(--card-background-color)
  );
  border-radius: 10%;
}

.grace-shopper-tech-stack-container {
  margin: 0.5em auto 0.5em;
}

.tech-stack-logo {
  width: 30%;
}

/* Tabs */

.tabs {
  width: 100%;
  margin: 3.5em auto 1.5em;
}

.tab-selectors {
  margin: 3em auto 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 3px solid var(--card-container-border-color);
  background: var(--tab-selectors-background-color);
  padding-left: 0px;
  box-shadow: var(--card-box-shadow);
}

.tab-selectors li {
  font-size: 0.8em;
  width: 100%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  white-space: nowrap;
}

.tab-selectors li:nth-child(2) {
  border-radius: 0;
  border-top: 3px solid black;
}

.tab-selectors li {
  background: var(--tab-selectors);
}
.tab-selectors li:hover {
  background: var(--tab-selector-hover);
}
.tab-selectors li.active {
  background: var(--tab-selector-active);
}

@media (min-width: 800px) {
  /* Projects */

  .tech-stack-logo-container {
    display: flex;
    justify-content: space-between;
  }

  .tech-stack-logo {
    width: 30%;
  }

  /* Tabs */
  .tabs {
    width: 100%;
    height: auto;
    margin: 1.5rem auto 1.5rem;
  }

  .tab-selectors {
    margin: -1em auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 3px solid var(--card-container-border-color);
    border-radius: 2rem;
    background: var(--tab-selectors-background-color);
    padding-left: 0px;
    box-shadow: var(--card-box-shadow);
  }

  .tab-selectors li {
    font-size: 0.8em;
    width: 100%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    white-space: nowrap;
  }

  .tab-selectors li:nth-child(2) {
    border-radius: 0;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
}
